function getCookie(name) {
    // Split cookie string and get all individual name=value pairs in an array
    var cookieArr = document.cookie.split(";");

    // Loop through the array elements
    for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");

        /* Removing whitespace at the beginning of the cookie name
        and compare it with the given string */
        if (name == cookiePair[0].trim()) {
            // Decode the cookie value and return
            return decodeURIComponent(cookiePair[1]);
        }
    }

    // Return null if not found
    return null;
}

function setCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

function DoContinue() {
    var e = document.getElementById("lang-alert-select");
    var value = e.value;
    setCookie('langalertClosed', 'true', 1);
    window.location.href = value;
}
function DoClose() {
    var x = document.getElementById("lang-alert");
    x.style.display = "none";
    setCookie('langalertClosed', 'true', 1);
}
function DoLanguageAlert(geoCountryCode, geoCountryLanguages, geoCountryNames, clientHeader, clientIP) {
    var defaultLanguage = "en-us";
    var chinaLanguage = "zh-cn";
    var defaultCountry = "United States";
    var hostname = window.location.hostname.toLowerCase();
    var parser = document.createElement('a');
    parser.href = window.location.href;
    var currPath = parser.pathname;
    var url = currPath.split('/');
    var pageLanguage = url[1].toLowerCase();
    if (pageLanguage == '') {
        pageLanguage = defaultLanguage;
    }
    if (!/^[a-z]{2}\-[a-z]{2}/.test(pageLanguage)) {
        pageLanguage = defaultLanguage;
    }
    var targetHostname = "";
    if (hostname.endsWith(".cn")) {
        targetHostname = '/' + '/' + hostname.replace(".cn", "");
        pageLanguage = chinaLanguage;
}
    var geoCountryLanguage = geoCountryLanguages[geoCountryCode];
    var geoCountryName = geoCountryNames[geoCountryCode];
    if (geoCountryLanguage === undefined) {
        geoCountryLanguage = defaultLanguage;
    }
    if (geoCountryName === undefined) {
        geoCountryName = defaultCountry;
    }
    console.log("lang: " + pageLanguage);
    console.log("geoCountryLanguage: " + geoCountryLanguage);
    console.log("geoCountryName: " + geoCountryName);
    console.log("geoCountryCode: " + geoCountryCode);
    var showLangAlert = false;
    if (geoCountryLanguage != pageLanguage) {
        showLangAlert = true;
    }
    if (geoCountryLanguage == chinaLanguage) {
        targetHostname = '/' + '/' + hostname + ".cn";
        geoCountryLanguage = "";
    }
    targetHostname = targetHostname.replace(".cn.cn", ".cn");
    console.log("targetHostname: " + targetHostname);
    var targetOption = document.getElementById('lang-alert-select').options[0];
    targetOption.value = targetHostname + '/' + geoCountryLanguage;
    targetOption.text = geoCountryName;
    if (showLangAlert) {
        var x = document.getElementById("lang-alert");
        x.style.display = "block";
    }
}
function DoLanguageBarInit(geoCountryLanguages, geoCountryNames, clientHeader, clientIP) {
    var langCookie = getCookie('langalertClosed');
    if (langCookie == null) {
        jQuery.ajax({
            url: "/cdn-cgi/trace",
            headers: { 'cf-connecting-ip': clientIP },
            success: function (response) {
                var lines = response.split("\n");
                var shouldSkip = false;
                var keyValue;
                lines.forEach(function (line) {
                    if (shouldSkip) {
                        return;
                    }
                    keyValue = line.split("=");
                    if (keyValue[0] == "loc") {
                        shouldSkip = true;
                    }
                });
                countryCode = decodeURIComponent(keyValue[1] || "").toLowerCase();
                DoLanguageAlert(countryCode, geoCountryLanguages, geoCountryNames, clientHeader, clientIP);
            },
            error: function () {
                countryCode = "us";
                DoLanguageAlert(countryCode, geoCountryLanguages, geoCountryNames, clientHeader, clientIP);
            }
        });
    }
}